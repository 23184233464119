<template>
	<div class="index-page">
		<NewHead />
		<div class="index-banner">
			<swiper class="swiper wjpaper-banner" :options="swiperOptionThumbs" ref="swiperThumbs">
				<swiper-slide>
					<div class="banner-item" :style="{backgroundImage:'url('+banner+')'}">
						<div class="container">
							<div class="banner-grid wow fadeInDown">
								<div class="banner-lg">头部轮播图的标题</div>
								<div class="banner-desc">这里是头部轮播图的标题说明。</div>
								<div class="banner-foot">
									<el-button type="cyan">头部主要按钮</el-button>
									<el-button type="white">头部次要按钮</el-button>
								</div>
							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
		</div>
		<!-- <div class="index-bar wow fadeInDown">
			<div class="container">
				<div class="raty-left">
					<span>3000多个品牌的大小都爱我们! </span>
					<el-rate
					v-model="rateValue"
					disabled
					:colors="colors"
					text-color="#1AA8F0"
					score-template="{value}">
					</el-rate>
					<span> 4.6谷歌评论</span>
				</div>
				<div class="ic">
					<img src="../assets/image/index/ic1.png" alt="" />
					<img src="../assets/image/index/ic2.png" alt="" />
					<img src="../assets/image/index/ic3.png" alt="" />
					<img src="../assets/image/index/ic4.png" alt="" />
					<img src="../assets/image/index/ic5.png" alt="" />
				</div>
			</div>
		</div> -->
		<div class="index-wrapper">
			<div class="index-section index-style-a ">
				<div class="container wow fadeInUp">
					<div class="section-head ">
						<div class="h2">解决方案 <span>标题+卡片</span></div>
						<p>解决方案标题说明</p>
					</div>
					<el-row :gutter="24">
						<el-col :xs="12" :sm="6" :md="6">
							<div class="custom-panel hover-up">
								<div class="cell-icon">
									<img src="../assets/image/index/cs-icon-1.png" alt="" />
								</div>
								<div class="cell-title">卡片一小标题</div>
								<div class="cell-desc">卡片一说明：在我们的产品专家团队的无限支持下，做出更明智的决定。</div>
							</div>
						</el-col>
						<el-col :xs="12" :sm="6" :md="6">
							<div class="custom-panel hover-up">
								<div class="cell-icon">
									<img src="../assets/image/index/cs-icon-2.png" alt="" />
								</div>
								<div class="cell-title">卡片二小标题：端到端解决方案</div>
								<div class="cell-desc">卡片二小说明：从概念到你的门，我们通过为你处理一切来简化你的项目。</div>
							</div>
						</el-col>
						<el-col :xs="12" :sm="6" :md="6">
							<div class="custom-panel hover-up">
								<div class="cell-icon">
									<img src="../assets/image/index/cs-icon-3.png" alt="" />
								</div>
								<div class="cell-title">卡片三小标题：定制尺寸</div>
								<div class="cell-desc">卡片三小说明：完全控制你的包装的大小，没有限制，以适应你的产品。</div>
							</div>
						</el-col>
						<el-col :xs="12" :sm="6" :md="6">
							<div class="custom-panel hover-up">
								<div class="cell-icon">
									<img src="../assets/image/index/cs-icon-4.png" alt="" />
								</div>
								<div class="cell-title">卡片四小标题：万嘉纸业的承诺</div>
								<div class="cell-desc">卡片四小说明：我们保证最高质量的产品和客户体验!</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="index-style-bg">
				<div class="index-section index-style-b">
					<div class="container wow fadeInUp">
						<div class="section-head flex">
							<div class="flex_bd">
								<div class="h2">产品列表 - 标题</div>
								<p>产品列表 - 标题说明。</p>
							</div>
							<el-button type="info" plain>主要按钮</el-button>
						</div>
						<el-row :gutter="24" type="flex" class="pack-row">
							<el-col :xs="12" :sm="8" :md="8" :lg="6" v-for="(item,index) in packlist" :key="index">
								<div class="pack-card hover-up">
									<div class="pack-photo scale">
										<img :src="item.pic" alt="" />
									</div>
									<div class="pack-grid">
										<div class="cell-title">{{item.title}}</div>
										<div class="cell-desc">{{item.desc}}</div>
									</div>
								</div>
							</el-col>
							<el-col :xs="12" :sm="8" :md="8" :lg="6">
								<div class="pack-more-card">
									<div class="p-title">在找别的东西吗?我们可以帮忙。</div>
									<el-button type="info" plain>请求定制报价</el-button>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="index-section index-style-c">
					<div class="container wow fadeInUp">
						<div class="section-head flex">
							<div class="flex_bd">
								<div class="h2">左右滑动</div>
								<p>找到适合你的行业的完美包装解决方案。</p>
							</div>
							<div class="k-arrow-box">
								<span class="k-arrow c--prev el-icon-arrow-left"></span>
								<span class="k-arrow c--next el-icon-arrow-right"></span>
							</div>
						</div>
					</div>
					<swiper class="case-swiper" :options="caseThumbs" ref="caseThumbs">
						<swiper-slide v-for="(item,index) in cases" :key="index">
							<div class="case-card ">
								<div class="case-photo scale"><img :src="item.pic" alt="" /></div>
								<div class="case-grid">
									<div class="cell-title">{{item.title}}</div>
									<div class="cell-desc">{{item.desc}}</div>
								</div>
							</div>
						</swiper-slide>
					</swiper>
					<div class="case-pagination"></div>
				</div>
				<div class="index-section index-style-c">
					<div class="container wow fadeInUp">
						<div class="section-head ">
							<div class="h2">两栏式(大标题+图中嵌文)</div>
							<p>用环保的包装替代品来增加你的业务。</p>
						</div>
						<el-row :gutter="24">
							<el-col :xs="12" :sm="12">
								<div class="continue-card hover-up">
									<img src="../assets/image/index/chixu1.png" alt="" />
									<div class="ct-grid">
										<div class="sm-label">环保包装</div>
										<div class="ct-title">环保与可持续的负责任包装</div>
										<el-button class="btn btn-view">浏览产品</el-button>
									</div>
								</div>
							</el-col>
							<el-col :xs="12" :sm="12">
								<div class="continue-card hover-up">
									<img src="../assets/image/index/chixu2.png" alt="" />
									<div class="ct-grid">
										<div class="sm-label">可持续性努力</div>
										<div class="ct-title">万嘉纸业追求绿色未来的使命</div>
										<el-button class="btn btn-view">学习我们是怎么做的</el-button>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="circle-box ">
					<img src="../assets/image/index/circle.png" alt="" />
				</div>
			</div>
			<div class="index-section index-style-d">
				<div class="container wow fadeInUp">
					<div class="section-head text-center">
						<div class="h2">多页签</div>
						<p>我们的 万嘉纸业办法 提供创建最佳包装解决方案所需的所有服务你们的产品和业务需要 总包装成功率 .</p>
					</div>
					<el-tabs v-model="tavName" class="serve-tabs" stretch>
						<el-tab-pane label="咨询" name="a">
							<div class="serv-tab-body">
								<el-row :gutter="24">
									<el-col :xs="12" :sm="12" :md="6">
										<div class="zx-card">
											<div class="cell-icon">
												<img src="../assets/image/index/serve-icon-1.png" alt="" />
											</div>
											<div class="cell-title">包装审计</div>
											<div class="cell-desc">识别和分析您当前的包装和改进您的包装的局部领域。</div>
										</div>
									</el-col>
									<el-col :xs="12" :sm="12" :md="6">
										<div class="zx-card">
										<div class="cell-icon">
											<img src="../assets/image/index/serve-icon-2.png" alt="" />
										</div>
										<div class="cell-title">包装战略</div>
										<div class="cell-desc">与我们的专家合作并开发一个定制的包装战略,以满足您的需求和目标。</div>
										</div>
									</el-col>
									<el-col :xs="12" :sm="12" :md="6">
										<div class="zx-card">
										<div class="cell-icon">
											<img src="../assets/image/index/serve-icon-3.png" alt="" />
										</div>
										<div class="cell-title">成本优化</div>
										<div class="cell-desc">与我们的专家合作并开发一个定制的包装战略,以满足您的需求和目标。</div>
										</div>
									</el-col>
									<el-col :xs="12" :sm="12" :md="6">
										<div class="zx-card">
										<div class="cell-icon">
											<img src="../assets/image/index/serve-icon-4.png" alt="" />
										</div>
										<div class="cell-title">供应链优化</div>
										<div class="cell-desc">分析和改进您现有的供应链,为最有效的采购网络。</div>
										</div>
									</el-col>
								</el-row>
							</div>
						</el-tab-pane>
						<el-tab-pane label="设计" name="b">设计</el-tab-pane>
						<el-tab-pane label="原型" name="c">原型</el-tab-pane>
						<el-tab-pane label="生产" name="d">生产</el-tab-pane>
						<el-tab-pane label="逻辑" name="e">逻辑</el-tab-pane>
						<el-tab-pane label="优化" name="f">优化</el-tab-pane>
					</el-tabs>
					<div class="serve-panel wow fadeInDown">
						<div class="serve-panel-photo"><img src="../assets/image/index/baozhuang.png" alt="" /></div>
						<div class="serve-ruc">
							<div class="s-head">
								<div class="h2"><span class="text-info">大图加说明</span></div>
								<div class="h1">采用360°巴基斯坦式进场</div>
							</div>
							<el-row :gutter="24">
								<el-col :xs="12" :sm="12" :md="6">
									<div class="cell-icon">
										<img src="../assets/image/index/b-icon-1.png" alt="" />
									</div>
									<div class="cell-title">节省成本和时间</div>
									<div class="cell-desc">受益于精心设计的解决方案,设计尽可能为您节省时间和金钱。</div>
								</el-col>
								<el-col :xs="12" :sm="12" :md="6">
									<div class="cell-icon">
										<img src="../assets/image/index/b-icon-2.png" alt="" />
									</div>
									<div class="cell-title">更可靠</div>
									<div class="cell-desc">每次都从认证的制造商那里获得可靠的产品。</div>
								</el-col>
								<el-col :xs="12" :sm="12" :md="6">
									<div class="cell-icon">
										<img src="../assets/image/index/b-icon-3.png" alt="" />
									</div>
									<div class="cell-title">更多品牌影响</div>
									<div class="cell-desc">让你的客户留下一个持久的印象,解决方案的品牌影响和认可。</div>
								</el-col>
								<el-col :xs="12" :sm="12" :md="6">
									<div class="cell-icon">
										<img src="../assets/image/index/b-icon-4.png" alt="" />
									</div>
									<div class="cell-title">变得可持续</div>
									<div class="cell-desc">从认证和可持续来源的一系列生态包装选择。</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</div>
			</div>
			<div class="index-section index-style-e">
				<div class="container wow fadeInUp">
					<div class="maker-cell">
						<div class="maker-photo">
							<img src="../assets/image/index/zzs.png" alt="" />
						</div>
						<div class="flex_bd" style="margin-left: 15px;">
							<div class="section-head ">
								<div class="h2">左图右文</div>
								<div class="h4">为您的所有定制包装盒和定制包装的目的地-通过在定制包装过程的每一步从概念到现实的支持,我们的客户能够实现他们的梦想包装的轻松。</div>
							</div>
							<ul class="maker-list">
								<li>
									<div class="cell-title">全球供应链</div>
									<div class="cell-desc">使用我们的全球包装供应链,访问任何定制包装,盒子和其他材料。</div>
								</li>
								<li>
									<div class="cell-title">50+认证设施</div>
									<div class="cell-desc">受益于来自全球50多个认证设施的认证定制包装。</div>
								</li>
								<li>
									<div class="cell-title">卓越制造</div>
									<div class="cell-desc">为您的产品添加一个更高的感知值与可靠的定制包装设计提供质量保证</div>
								</li>
							</ul>
						</div>
						
					</div>
				</div>
			</div>
			<div class="index-section index-style-e">
				<div class="container wow fadeInUp">
					<div class="maker-cell">
						<div class="flex_bd">
							<div class="section-head ">
								<div class="h2">左文右图</div>
								<div class="h4">为您的所有定制包装盒和定制包装的目的地-通过在定制包装过程的每一步从概念到现实的支持,我们的客户能够实现他们的梦想包装的轻松。</div>
							</div>
							<ul class="maker-list">
								<li>
									<div class="cell-title">全球供应链</div>
									<div class="cell-desc">使用我们的全球包装供应链,访问任何定制包装,盒子和其他材料。</div>
								</li>
								<li>
									<div class="cell-title">50+认证设施</div>
									<div class="cell-desc">受益于来自全球50多个认证设施的认证定制包装。</div>
								</li>
								<li>
									<div class="cell-title">卓越制造</div>
									<div class="cell-desc">为您的产品添加一个更高的感知值与可靠的定制包装设计提供质量保证</div>
								</li>
							</ul>
						</div>
						<div class="maker-photo">
							<img src="../assets/image/index/zzs.png" alt="" />
						</div>
					</div>
				</div>
			</div>
			<div class="index-section index-style-f">
				<div class="container wow fadeInUp">
					<div class="section-head flex">
						<div class="flex_bd">
							<div class="h2">客户反馈</div>
							<p>不要让我们说的话影响你,从我们的顾客那里拿走!</p>
						</div>
						<div class="k-arrow-box">
							<span class="k-arrow k--prev el-icon-arrow-left"></span>
							<span class="k-arrow k--next el-icon-arrow-right"></span>
						</div>
					</div>
				</div>
				<swiper class="kehu-swiper wow fadeInDown" :options="kehuThumbs" ref="kehuThumbs">
					<swiper-slide v-for="(item,index) in 10" :key="index">
						<div class="kh-card">
							<div class="kh-bd">
								<div class="kh-content">很棒的经验和万嘉纸业一起合作。在选择万嘉纸业之前，我和许多供应商谈过，我很感激和他们一起合作。</div>
								<div class="kh-raty">
									<span class="el-icon-star-on"></span>
									<span class="el-icon-star-on"></span>
									<span class="el-icon-star-on"></span>
									<span class="el-icon-star-on"></span>
									<span class="el-icon-star-on"></span>
								</div>
							</div>
							<div class="kh-bar">
								<img src="../assets/image/index/jasd.png" alt="" />
								<div class="flex_bd">
									<h3>克里斯汀</h3>
									<p>用心感受生活</p>
								</div>
							</div>
						</div>
					</swiper-slide>
				</swiper>
			</div>
			<div class="index-section index-style-g">
				<div class="container wow fadeInUp">
					<div class="section-head text-center">
						<div class="h2">知识问答</div>
					</div>
					<div class="index-collapse">
						<el-collapse v-model="activeNames" accordion>
							<el-collapse-item :title="item.title" :name="index" v-for="(item,index) in question" :key="index">
								<div v-html="item.content"></div>
							</el-collapse-item>
						</el-collapse>
					</div>
					<div class="contact-cell wow fadeInDown">
						<div class="contact-avatr">
							<img src="../assets/image/index/NoPath1x.png" alt="" />
						</div>
						<div class="flex_bd">
							有其他问题吗？ <a href="">直接联系我们的客服</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<NewFoot />
		
	</div>
</template>

<script>
	import NewHead from '@/components/Head.vue'
	import NewFoot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			NewHead,
			NewFoot,
		},
		data() {
			return {
				rateValue: 4.5,
				colors:["#1AA8F0","#1AA8F0","#1AA8F0","#1AA8F0","#1AA8F0"],
				kehuThumbs: {
					loop: true,
					slidesPerView: 'auto',
					centeredSlides: true,
					spaceBetween: 20,
					navigation: {
						nextEl: '.k--next',
						prevEl: '.k--prev'
					}
				},
				caseThumbs: {
					loop: true,
					slidesPerView: 'auto',
					centeredSlides: true,
					spaceBetween: 20,
					pagination: {
						el: '.case-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.c--next',
						prevEl: '.c--prev'
					}
				},
				packlist:[
					{
						title:'产品包装',
						desc:'用薄而灵活的纸板做的标准卡盒。',
						pic:require('@/assets/image/index/pic-1.png')
					},{
						title:'胶合盒',
						desc:'耐用的三层瓦楞纸板箱',
						pic:require('@/assets/image/index/pic-2.png')
					},{
						title:'坚硬的盒子',
						desc:'豪华包装用厚实耐用的纸板制成。',
						pic:require('@/assets/image/index/pic-3.png')
					},{
						title:'箱体',
						desc:'保持您的宽松产品良好的包装，展示和保护。',
						pic:require('@/assets/image/index/pic-4.png')
					},{
						title:'产品包装',
						desc:'用薄而灵活的纸板做的标准卡盒。',
						pic:require('@/assets/image/index/pic-1.png')
					},{
						title:'胶合盒',
						desc:'耐用的三层瓦楞纸板箱',
						pic:require('@/assets/image/index/pic-2.png')
					},{
						title:'坚硬的盒子',
						desc:'豪华包装用厚实耐用的纸板制成。',
						pic:require('@/assets/image/index/pic-3.png')
					},{
						title:'箱体',
						desc:'保持您的宽松产品良好的包装，展示和保护。',
						pic:require('@/assets/image/index/pic-4.png')
					},{
						title:'产品包装',
						desc:'用薄而灵活的纸板做的标准卡盒。',
						pic:require('@/assets/image/index/pic-1.png')
					},{
						title:'胶合盒',
						desc:'耐用的三层瓦楞纸板箱',
						pic:require('@/assets/image/index/pic-2.png')
					},{
						title:'坚硬的盒子',
						desc:'豪华包装用厚实耐用的纸板制成。',
						pic:require('@/assets/image/index/pic-3.png')
					},{
						title:'箱体',
						desc:'保持您的宽松产品良好的包装，展示和保护。',
						pic:require('@/assets/image/index/pic-4.png')
					},{
						title:'产品包装',
						desc:'用薄而灵活的纸板做的标准卡盒。',
						pic:require('@/assets/image/index/pic-1.png')
					},{
						title:'胶合盒',
						desc:'耐用的三层瓦楞纸板箱',
						pic:require('@/assets/image/index/pic-2.png')
					},
				],
				cases:[
					{
						title:'窗口包装',
						desc:'搭配您的服装与独一无二的定制印刷服装盒。',
						pic:require('@/assets/image/index/fangan3.png')
					},
					{
						title:'服装和时装包装',
						desc:'搭配您的服装与独一无二的定制印刷服装盒。',
						pic:require('@/assets/image/index/fangan2.png')
					},{
						title:'蜡烛包装',
						desc:'搭配您的服装与独一无二的定制印刷服装盒。',
						pic:require('@/assets/image/index/fangan4.png')
					},{
						title:'面包和蛋糕包装',
						desc:'搭配您的服装与独一无二的定制印刷服装盒。',
						pic:require('@/assets/image/index/fangan5.png')
					},{
						title:'巧克力包装',
						desc:'搭配您的服装与独一无二的定制印刷服装盒。',
						pic:require('@/assets/image/index/fangan1.png')
					}
				],
				activeNames: [0],
				question:[
					{
						title:'你们最低订单数量是多少？',
						content:'我们的最低订单量(MOQ)取决于具体的产品线和定制要求。请与我们的产品专家联系,了解您感兴趣的产品的详细信息。你也可以看看我们的生活质量清单 在这里 .我们努力适应各种规格的订单,以满足客户的不同需求,所以不要犹豫 联系我们 !'
					},{
						title:'你们能生产处少于最低订单数量的产品吗？',
						content:'我们的最低订单量(MOQ)取决于具体的产品线和定制要求。请与我们的产品专家联系,了解您感兴趣的产品的详细信息。你也可以看看我们的生活质量清单 在这里 .我们努力适应各种规格的订单,以满足客户的不同需求,所以不要犹豫 联系我们 !'
					},{
						title:'你们的制作时间是多长？',
						content:'我们的最低订单量(MOQ)取决于具体的产品线和定制要求。请与我们的产品专家联系,了解您感兴趣的产品的详细信息。你也可以看看我们的生活质量清单 在这里 .我们努力适应各种规格的订单,以满足客户的不同需求,所以不要犹豫 联系我们 !'
					},{
						title:'你们的产品都可以回收吗？',
						content:'我们的最低订单量(MOQ)取决于具体的产品线和定制要求。请与我们的产品专家联系,了解您感兴趣的产品的详细信息。你也可以看看我们的生活质量清单 在这里 .我们努力适应各种规格的订单,以满足客户的不同需求,所以不要犹豫 联系我们 !'
					},{
						title:'在我的定制包装投入生产之前，我会收到一个样品吗？',
						content:'我们的最低订单量(MOQ)取决于具体的产品线和定制要求。请与我们的产品专家联系,了解您感兴趣的产品的详细信息。你也可以看看我们的生活质量清单 在这里 .我们努力适应各种规格的订单,以满足客户的不同需求,所以不要犹豫 联系我们 !'
					}
				],
				tavName:'a',




				banner: require('../assets/image/index/bannerx.jpg'),
				swiperOptionThumbs: {
					loop: true,
					autoplay: 4000,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					navigation: {
						nextEl: '.swiper--next',
						prevEl: '.swiper--prev'
					}
				},
			};
		},
		methods: {

		},
		mounted() {
			new this.$wow.WOW().init();
		}
	}
</script>

<style scoped>
</style>